<template>
    <div class="modal">
        <div class="modal-close" @click="$emit('close')"></div>
        <div class="modal-container">
            <form @submit.prevent="onSubmit">
              <template v-if="!success">
              <div class="row">
                    <!-- name -->
                    <div class="form-group" :class="{ error: v$.form.name.$errors.length }">
                        <input class="form-control" :placeholder="$t('career.form.name')" type="text" v-model="v$.form.name.$model">
                        <!-- error message -->
                        <template v-if="v$.form.name.$errors.length > 0">
                            <div class="error-msg" v-if="v$.form.firstname.$errors[0].$validator == 'required'">{{ $t("career.form.required") }}</div>
                            <div class="error-msg" v-if="v$.form.firstname.$errors[0].$validator == 'max'">{{ $t("career.form.max", {max : v$.form.name.$errors[0].$params.max}) }}</div>
                        </template>
                    </div>                  
              </div>

                <div class="row">
                    <!-- Email -->
                    <div class="form-group" :class="{ error: v$.form.email.$errors.length }">
                        <input class="form-control" :placeholder="$t('career.form.email')" type="email" v-model="v$.form.email.$model">
                        <!-- error message -->
                        <template v-if="v$.form.email.$errors.length > 0">                
                            <div class="error-msg" v-if="v$.form.email.$errors[0].$validator == 'required'">C{{ $t("career.form.required") }}</div>
                            <div class="error-msg" v-if="v$.form.email.$errors[0].$validator == 'email'">{{ $t("career.form.invalidEmail") }}</div>
                            <div class="error-msg" v-if="v$.form.email.$errors[0].$validator == 'max'">{{ $t("career.form.max", {max : v$.form.email.$errors[0].$params.max}) }}</div>
                        </template>
                    </div>
                </div>

              <div class="row">
                    <!-- Phone -->
                    <div class="form-group" :class="{ error: v$.form.phone.$errors.length }">
                        <input class="form-control" :placeholder="$t('career.form.phone')" type="text" v-model="v$.form.phone.$model">
                        <!-- error message -->
                        <template v-if="v$.form.phone.$errors.length > 0">
                            <div class="error-msg" v-if="v$.form.phone.$errors[0].$validator == 'max'">{{ $t("career.form.max", {max : v$.form.phone.$errors[0].$params.max}) }}</div>
                        </template>
                    </div>
                </div>

                <div class="row">                
                    <!-- Job -->
                    <div class="form-group" :class="{ error: v$.form.job.$errors.length }">
                        <input class="form-control" :placeholder="$t('career.form.job')" type="text" v-model="v$.form.job.$model">
                        <!-- error message -->
                        <template v-if="v$.form.job.$errors.length > 0">
                            <div class="error-msg" v-if="v$.form.job.$errors[0].$validator == 'max'">{{ $t("career.form.max", {max : v$.form.job.$errors[0].$params.max}) }}</div>
                        </template>
                    </div>                
                </div>

                <div class="row">
                    <!-- Message -->
                    <div class="form-group" :class="{ error: v$.form.message.$errors.length }">
                        <textarea class="form-control" :placeholder="$t('career.form.message')" v-model="v$.form.message.$model" rows="5"></textarea>
                        <!-- error message -->
                        <template v-if="v$.form.message.$errors.length > 0">
                            <div class="input-errors" v-for="(error, index) of v$.form.message.$errors" :key="index">
                                <div class="error-msg" v-if="v$.form.message.$errors[0].$validator == 'required'">{{ $t("career.form.required") }}</div>
                                    <div class="error-msg" v-if="v$.form.message.$errors[0].$validator == 'max'"> {{ $t("career.form.max", {max : v$.form.message.$errors[0].$params.max}) }}</div>
                            </div>
                        </template>
                    </div>
                </div>

                <div class="row">
                    <!-- file -->
                    <div class="form-group">
                        <input class="form-control"  :placeholder="$t('career.form.file')" v-on:change="handlePdfUpload($event)" type="file" accept="application/pdf" />
                        <div class="error-msg" v-if="errorPdf">{{ errorPdf }}</div>
                        <!-- error message -->
                        <!-- <template v-if="v$.form.message.$errors.length > 0">
                            <div class="input-errors" v-for="(error, index) of v$.form.message.$errors" :key="index">
                                <div class="error-msg" v-if="v$.form.message.$errors[0].$validator == 'required'">{{ $t("career.form.required") }}</div>
                                    <div class="error-msg" v-if="v$.form.message.$errors[0].$validator == 'max'">Ce champs ne doit pas dépasser {{v$.form.message.$errors[0].$params.max}} caractères</div>
                            </div>
                        </template> -->
                    </div>
                </div>

                <div class="row">
                    <!-- rgpd -->
                    <div class="form-group" :class="{ error: errCheck}">
                        <div class="checkbox-container">
                            <input class="form-control" type="checkbox" v-model="v$.form.rgpd.$model" @click="getChecked(v$.form.rgpd.$model)">
                            <label>{{ $t("career.form.rgpd") }} <router-link :to="{ name : 'DataPrivacy' }" target="_blank">{{ $t("career.form.rgpdLink") }}</router-link></label>
                        </div>
                        <!-- error message -->
                        <template v-if="v$.form.rgpd.$errors.length > 0">
                            <div class="error-msg" v-if="v$.form.message.$errors[0].$validator == 'required'">{{ $t("career.form.required") }}</div>
                        </template>
                    </div>
                </div>
                
                <!-- Submit Button -->
                <div class="row-btn">
                    <button :disabled="v$.form.$invalid" class="btn">{{ $t("career.form.btn") }}</button>
                </div>
                </template>
                <!-- Success -->
                <div class="notices success" v-if="success">{{ $t("career.form.success")}}}</div>
                <!-- Errors -->
                <div class="notices errors" v-if="errors">{{ errors}}</div>
          </form>
            <div class="loader-container">
                <loader v-if="isLoading"></loader>
            </div>
        </div>
    </div>
</template>

<script>
import useVuelidate from '@vuelidate/core';
import { required, email, maxLength } from '@vuelidate/validators';
import axios from 'axios';
import Loader from './Loader.vue';

export default {
    name : "CareerModal",
    components : {
        Loader
    },
    data() {
        return {
            form : {
                name : "",
                email : "",
                phone : "",
                job : "",
                message : "",
                file : "",
                rgpd : false
            },
            errorPdf : null,
            success : false,
            errors : null,
            isLoading : false,
            errCheck : false
        }
    },
    setup () {
        return { v$: useVuelidate() }
    },
    validations() {
        return {
            form : {
                name : {
                    required, max: maxLength(150) 
                },    
                email: {
                    required, email, max: maxLength(150) 
                },
                phone : {
                    max: maxLength(20) 
                },  
                job : {
                    max: maxLength(150), required
                }, 
                message : {
                    required, max: maxLength(1500) 
                },
                rgpd : {
                    required,
                    checked: value => value === true
                }
            }
        }        
    },    
    methods : {
        onSubmit() {
            let config = {
                validateStatus: () => true,  
            };
            let vm = this;
            this.errors = null;
            this.success = false;

            this.form.site = 2;

            axios
                .post(`${this.$store.state.apiUrl}/public/job/2`, this.form,  config)
                .then(function (res) { 
                    if(res.status != 200) {
                        vm.errors =  res.data.errors;                  
                    }else{
                        vm.success = true;
                    
                    }
                })
                .catch(error => {
                    console.log(error.response.data.errors);
                    vm.errors = vm.$t("career.form.error");
                });
                
        },
        handlePdfUpload(event){
            this.errorPdf = null;
            const formData = new FormData();
            formData.append('pdf', event.target.files[0]);

            let config = {
                validateStatus: () => true,            
            };
            let vm = this;
            axios
            .post(`${this.$store.state.apiUrl}/public/upload-pdf`, formData, config)
                    .then(function (res) {
                        if(res.status != 200) {
                            vm.errorPdf = res.data.message;
                        }else{
                            vm.form.file = res.data.pdf;
                        }
            
                    })   
      },
    }
}
</script>

<style scoped lang="scss">
    form {
        width:800px;
        max-width:96%;
        margin:0 auto;
        position:relative;
        z-index:1;
    }

    .form-group {
        width:100%;
        display: flex;
        flex-direction: column;
        margin:10px 0;
    }

    .loader-container {
        display: flex;
        justify-content: center;
        width: 100%;
    }

    .btn {
        margin-top:20px;
    }


</style>