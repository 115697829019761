<template>
  <div class="main">
    <div class="box">
      <div class="row">
        <div class="col-40">
          <p v-html="$t('career.text1')"></p>
          <p v-html="$t('career.text2')"></p>
          <p v-html="$t('career.text3')"></p>
          <p v-html="$t('career.text4')"></p>
          <p v-html="$t('career.text5')"></p>
          <div class="btn" @click="openModal = true">{{ $t("career.btn")}}</div>
        </div>
        <div class="col-60">
          <blog :cat="[5]"></blog>
        </div>
      </div>
    </div>
  </div>
    <Transition>
      <career-modal
        v-if="openModal"
        v-on:close="openModal = false"
      >
      </career-modal>
    </Transition>
    
</template>

<script>
import CareerModal from "./../components/CareerModal.vue";
import Blog from "./../components/Blog.vue";

export default {
  name: "Career",
  components : { CareerModal, Blog },
  data() {
    return {
      openModal : false
    }
  }
}
</script>

<style scoped lang="scss">

.row {
  margin-top:40px;
  margin-bottom:40px;
}

.col-40 {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding-right:20px;
  border-right:2px solid $mainColor;
}

p {
  font-size:20px;
  text-align: right;
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

@media screen and (max-width:768px) {
  p {
    font-size:15px;
  }
}
</style>